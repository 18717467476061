import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class CenterService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  get(data): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.CENTER(), data);
  }

  count(data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_COUNT(), data);
  }

  countBranch(data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_BRANCH_COUNT(), data);
  }

  getBranches(id, data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_BRANCHES(id), data);
  }

  detail(id): Observable<any> {
    return this.getRequest(AppUrl.CENTER(id));
  }

  detailByUniqueId(id): Observable<any> {
    return this.getRequest(AppUrl.CENTER_BY_UNIQUE_ID(id));
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.CENTER(), data);
  }

  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.CENTER(id), data);
  }

  updatePassword(id, data): Observable<any> {
    return this.putRequest(AppUrl.CENTER_PASSWORD(id), data);
  }

  assignAdmin(id, data): Observable<any> {
    return this.putRequest(AppUrl.ASSIGN_CENTER_ADMIN(id), data);
  }

  updateStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.CENTER_STATUS(id), data);
  }

  getSharePercentage(data): Observable<any> {
    return this.getRequest(AppUrl.GET_SHARE_PERCENTAGE(), data);
  }

  /*getAdmissionReport(data): Observable<any> {
    return this.getRequest(AppUrl.GET_ADMISSION_REPORT(), data);
  }*/

  updateSharePercentage(user_id, session_id, data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_SHARE_PERCENTAGE(user_id, session_id), data);
  }

  updateCourseWiseSharePercentage(user_id, session_id, data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_COURSE_WISE_SHARE_PERCENTAGE(user_id, session_id), data);
  }

  updateAllCourseWiseSharePercentage(user_id, session_id, data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_ALL_COURSE_WISE_SHARE_PERCENTAGE(user_id, session_id), data);
  }

  deleteAllCourseWiseSharePercentage(user_id, session_id): Observable<any> {
    return this.deleteRequest(AppUrl.DELETE_ALL_COURSE_WISE_SHARE_PERCENTAGE(user_id, session_id));
  }

  addingBranchStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.ADDING_BRANCH_STATUS(id), data);
  }

  updateVisibleSessions(id, data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_VISIBLE_SESSIONS(id), data);
  }

  updateAmount(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.CENTER_AMOUNT(unique_id), data);
  }

  getTotalStudentCount(id): Observable<any> {
    return this.getRequest(AppUrl.TOTAL_STUDENT_COUNT(id));
  }

  getTodaysTotalStudentCount(id, data): Observable<any> {
    return this.getRequest(AppUrl.TODAYS_TOTAL_STUDENT_COUNT(id), data);
  }
  getAdmissionReport(data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_ADMISSION_REPORT(), data);
  }

  admissionReport(data):Observable<any> {
    return this.getRequest(AppUrl.ADMISSION_REPORT(),data);
  }
  downloadAdmissionReport(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_ADMISSION_REPORT(),data);
  }
  getCenterAdmissionReport(data): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.CENTER_ADMISSION_REPORT(), data);
  }

  getCenterReRegistrationReport(data): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.CENTER_RE_REGISTRATION_REPORT(), data);
  }

  deleteCenter(id): Observable<any> {
    return this.deleteRequest(AppUrl.CENTER(id));
  }
}
