import {Injectable} from '@angular/core';

@Injectable()
export class PermissionConstant {
  // General
  static ALL = 'ALL';
  // Sessions
  static ADD_SESSION = 'SESSIONS_ADD_SESSION';
  static LIST_SESSION = 'SESSIONS_LIST_SESSION';
  static RE_REGISTER_SESSION = 'SESSIONS_RE_REGISTER_SESSION';
  static LIST_RE_REGISTERED_SESSION = 'SESSIONS_LIST_RE_REGISTERED_SESSION';

  // Modules
  static PROGRAMS = 'MODULES_PROGRAMS';
  static COURSES = 'MODULES_COURSES';
  static BRANCHES = 'MODULES_BRANCHES';
  static MAPPINGS = 'MODULES_MAPPINGS';
  static SUBJECTS = 'MODULES_SUBJECTS';

  // Centers
  static ADD_CENTER = 'CENTERS_ADD_CENTER';
  static LIST_CENTER = 'CENTERS_LIST_CENTER';
  static SHARE_PERCENTAGE = 'CENTERS_SHARE_PERCENTAGE';
  static ADMISSION_REPORT = 'CENTERS_ADMISSION_REPORT';
  static IA_AW_MARKS = 'CENTERS_IA_AW_MARKS';
  static CENTER_BRANCHES = 'CENTERS_LIST_CENTER_BRANCHES';
  static CENTER_VERIFICATION = 'CENTERS_VERIFICATION_DETAILS';

  // Students
  static DIRECT_STUDENTS = 'STUDENTS_DIRECT_STUDENTS';
  static CENTER_STUDENTS = 'STUDENTS_CENTER_STUDENTS';
  static VERIFY_DIRECT_STUDENTS = 'STUDENTS_VERIFY_DIRECT_STUDENTS';
  static VERIFY_CENTER_STUDENTS = 'STUDENTS_VERIFY_CENTER_STUDENTS';
  static ASSIGN_ENROLLMENT = 'STUDENTS_ASSIGN_ENROLLMENT';
  static FINAL_VERIFICATION = 'STUDENTS_FINAL_VERIFICATION';
  static ASSIGN_ROLL_NO = 'STUDENTS_ASSIGN_ROLL_NO';
  static RE_REGISTERED_STUDENTS = 'STUDENTS_RE_REGISTERED_STUDENTS';
  static TRANSFER_REQUESTS = 'STUDENTS_TRANSFER_REQUESTS';
  static CENTER_STUDENTS_REPORT = 'STUDENTS_CENTER_STUDENTS_REPORT';

  // Accounts
  static CENTERS_ADD_MONEY = 'ACCOUNTS_CENTERS_ADD_MONEY';
  static CENTERS_TRANSACTIONS = 'ACCOUNTS_CENTERS_TRANSACTIONS';
  static CENTERS_RECEIPTS = 'ACCOUNTS_CENTERS_RECEIPTS';
  static CENTERS_REPORT = 'ACCOUNTS_CENTERS_BRANCH_REPORT';
  static CENTERS_BRANCH_REPORT = 'ACCOUNTS_CENTERS_REPORT';

  // Support
  static ADMISSION_TICKETS = 'SUPPORT_ADMISSION_TICKETS';
  static RE_REGISTRATION_TICKETS = 'SUPPORT_RE_REGISTRATION_TICKETS';
  static EXAMINATION_TICKETS = 'SUPPORT_EXAMINATION_TICKETS';
  static MARKSHEET_TICKETS = 'SUPPORT_MARKSHEET_TICKETS';
  static TRANSCRIPT_TICKETS = 'SUPPORT_TRANSCRIPT_TICKETS';
  static REVALUATION_TICKETS = 'SUPPORT_REVALUATION_TICKETS';
  static MIGRATION_TICKETS = 'SUPPORT_MIGRATION_TICKETS';
  static ORIGINAL_DEGREE_TICKETS = 'SUPPORT_ORIGINAL_DEGREE_TICKETS';
  static E_LEARNING_TICKETS = 'SUPPORT_E_LEARNING_TICKETS';
  static OTHER_TICKETS = 'SUPPORT_OTHER_TICKETS';

  // Issues
  static MARKSHEET_ISSUES = 'ISSUES_MARKSHEET_ISSUES';
  static TRANSCRIPT_ISSUES = 'ISSUES_TRANSCRIPT_ISSUES';
  static REVALUATION_ISSUES = 'ISSUES_REVALUATION_ISSUES';
  static MIGRATION_ISSUES = 'ISSUES_MIGRATION_ISSUES';
  static ORIGINAL_DEGREE_ISSUES = 'ISSUES_ORIGINAL_DEGREE_ISSUES';
  static PROVISIONAL_ISSUES = 'ISSUES_PROVISIONAL_ISSUES';
  static OTHER_ISSUES = 'ISSUES_OTHER_TICKETS';


  // Access
  static ROLES = 'ACCESS_ROLES';
  static PERMISSIONS = 'ACCESS_PERMISSIONS';

  // Departments
  static ADD_DEPARTMENT = 'DEPARTMENTS_ADD_DEPARTMENT';
  static LIST_DEPARTMENT = 'DEPARTMENTS_LIST_DEPARTMENT';
  static ADD_EMPLOYEE = 'DEPARTMENTS_ADD_EMPLOYEE';
  static LIST_EMPLOYEE = 'DEPARTMENTS_LIST_EMPLOYEE';
  static ADD_VIDEO_LECTURE = 'DEPARTMENTS_ADD_VIDEO_LECTURE';
  static LIST_VIDEO_LECTURE = 'DEPARTMENTS_LIST_VIDEO_LECTURE';
  static ADD_SLM = 'DEPARTMENTS_ADD_SLM';
  static LIST_SLM = 'DEPARTMENTS_LIST_SLM';
  static ADD_ASSIGNMENT = 'DEPARTMENTS_ADD_ASSIGNMENT';
  static LIST_ASSIGNMENT = 'DEPARTMENTS_LIST_ASSIGNMENT';

  // Exams
  static EXAM_SESSION = 'EXAMS_EXAM_SESSIONS';
  static EXAMS_SCHEDULE_EXAM = 'EXAMS_SCHEDULE_EXAM';
  static EXAMS_MARKED_BACK_EXAMS = 'EXAMS_MARKED_BACK_EXAMS';
  static DOCS_MARKSHEET_UPDATE_DELIVERY_DATE = 'DOCS_MARKSHEET_UPDATE_DELIVERY_DATE';
  static DOCS_MARKSHEET_UPDATE_CONSIGNMENT_NO = 'DOCS_MARKSHEET_UPDATE_CONSIGNMENT_NO';
  static DOCS_MARKSHEET_UPDATE_PRINT_STATUS = 'DOCS_MARKSHEET_UPDATE_PRINT_STATUS';
  static DOCS_MARKSHEET_VERIFY_AMOUNT = 'DOCS_MARKSHEET_VERIFY_AMOUNT';
  static DOCS_MARKSHEET_VERIFY_DOC = 'DOCS_MARKSHEET_VERIFY_DOC';
  static DOCS_MARKSHEET = 'DOCS_MARKSHEET';
  static DOCS_CMM_UPDATE_DELIVERY_DATE = 'DOCS_CMM_UPDATE_DELIVERY_DATE';
  static DOCS_CMM_UPDATE_CONSIGNMENT_NO = 'DOCS_CMM_UPDATE_CONSIGNMENT_NO';
  static DOCS_CMM_UPDATE_PRINT_STATUS = 'DOCS_CMM_UPDATE_PRINT_STATUS';
  static DOCS_CMM_VERIFY_AMOUNT = 'DOCS_CMM_VERIFY_AMOUNT';
  static DOCS_CMM_VERIFY_DOC = 'DOCS_CMM_VERIFY_DOC';
  static DOCS_CMM = 'DOCS_CMM';
  static DOCS_PROVISIONAL = 'DOCS_PROVISIONAL';
  static DOCS_ORIGINAL_DEGREE_UPDATE_DELIVERY_DATE = 'DOCS_ORIGINAL_DEGREE_UPDATE_DELIVERY_DATE';
  static DOCS_ORIGINAL_PROVISIONAL_DELIVERY_DATE = 'DOCS_ORIGINAL_PROVISIONAL_DELIVERY_DATE';
  static DOCS_ORIGINAL_DEGREE_UPDATE_CONSIGNMENT_NO = 'DOCS_ORIGINAL_DEGREE_UPDATE_CONSIGNMENT_NO';
  static DOCS_PROVISIONAL_UPDATE_CONSIGNMENT_NO = 'DOCS_PROVISIONAL_UPDATE_CONSIGNMENT_NO';
  static DOCS_ORIGINAL_DEGREE_UPDATE_PRINT_STATUS = 'DOCS_ORIGINAL_DEGREE_UPDATE_PRINT_STATUS';
  static DOCS_PROVISIONAL_UPDATE_PRINT_STATUS = 'DOCS_PROVISIONAL_UPDATE_PRINT_STATUS';
  static DOCS_ORIGINAL_DEGREE_VERIFY_AMOUNT = 'DOCS_ORIGINAL_DEGREE_VERIFY_AMOUNT';
  static DOCS_PROVISIONAL_VERIFY_AMOUNT = 'DOCS_PROVISIONAL_VERIFY_AMOUNT';
  static DOCS_ORIGINAL_DEGREE_VERIFY_DOC = 'DOCS_ORIGINAL_DEGREE_VERIFY_DOC';
  static DOCS_PROVISIONAL_VERIFY_DOC = 'DOCS_PROVISIONAL_VERIFY_DOC';
  static DOCS_ORIGINAL_DEGREE = 'DOCS_ORIGINAL_DEGREE';
  static DOCS_BONAFIDE_LETTER = 'DOCS_BONAFIDE_LETTER';
  static DOCS_BONAFIDE_LETTER_VERIFY_DOC = 'DOCS_BONAFIDE_LETTER_VERIFY_DOC';
  static DOCS_BONAFIDE_LETTER_VERIFY_AMOUNT = 'DOCS_BONAFIDE_LETTER_VERIFY_AMOUNT';
  static DOCS_BONAFIDE_LETTER_UPDATE_PRINT_STATUS = 'DOCS_BONAFIDE_LETTER_UPDATE_PRINT_STATUS';
  static DOCS_BONAFIDE_LETTER_UPDATE_CONSIGNMENT_NO = 'DOCS_BONAFIDE_LETTER_UPDATE_CONSIGNMENT_NO';
  static DOCS_BONAFIDE_LETTER_UPDATE_DELIVERY_DATE = 'DOCS_BONAFIDE_LETTER_UPDATE_DELIVERY_DATE';
  static DOCS_BONAFIDE_LETTER_VIEW_AMOUNT = 'DOCS_BONAFIDE_LETTER_VIEW_AMOUNT';
  static DOCS_BONAFIDE_LETTER_PRINT_LABEL = 'DOCS_BONAFIDE_LETTER_PRINT_LABEL';
  static DOCS_DUPLICATE_DEGREE = 'DOCS_DUPLICATE_DEGREE';
  static DOCS_DUPLICATE_DEGREE_VERIFY_DOC = 'DOCS_DUPLICATE_DEGREE_VERIFY_DOC';
  static DOCS_DUPLICATE_DEGREE_VERIFY_AMOUNT = 'DOCS_DUPLICATE_DEGREE_VERIFY_AMOUNT';
  static DOCS_DUPLICATE_DEGREE_UPDATE_PRINT_STATUS = 'DOCS_DUPLICATE_DEGREE_UPDATE_PRINT_STATUS';
  static DOCS_DUPLICATE_DEGREE_UPDATE_CONSIGNMENT_NO = 'DOCS_DUPLICATE_DEGREE_UPDATE_CONSIGNMENT_NO';
  static DOCS_DUPLICATE_DEGREE_UPDATE_DELIVERY_DATE = 'DOCS_DUPLICATE_DEGREE_UPDATE_DELIVERY_DATE';
  static DOCS_DUPLICATE_DEGREE_VIEW_AMOUNT = 'DOCS_DUPLICATE_DEGREE_VIEW_AMOUNT';
  static DOCS_DUPLICATE_DEGREE_PRINT_LABEL = 'DOCS_DUPLICATE_DEGREE_PRINT_LABEL';
  static DOCS_DUPLICATE_MARKSHEET = 'DOCS_DUPLICATE_MARKSHEET';
  static DOCS_DUPLICATE_MARKSHEET_VERIFY_DOC = 'DOCS_DUPLICATE_MARKSHEET_VERIFY_DOC';
  static DOCS_DUPLICATE_MARKSHEET_VERIFY_AMOUNT = 'DOCS_DUPLICATE_MARKSHEET_VERIFY_AMOUNT';
  static DOCS_DUPLICATE_MARKSHEET_UPDATE_PRINT_STATUS = 'DOCS_DUPLICATE_MARKSHEET_UPDATE_PRINT_STATUS';
  static DOCS_DUPLICATE_MARKSHEET_UPDATE_CONSIGNMENT_NO = 'DOCS_DUPLICATE_MARKSHEET_UPDATE_CONSIGNMENT_NO';
  static DOCS_DUPLICATE_MARKSHEET_UPDATE_DELIVERY_DATE = 'DOCS_DUPLICATE_MARKSHEET_UPDATE_DELIVERY_DATE';
  static DOCS_DUPLICATE_MARKSHEET_VIEW_AMOUNT = 'DOCS_DUPLICATE_MARKSHEET_VIEW_AMOUNT';
  static DOCS_DUPLICATE_MARKSHEET_PRINT_LABEL = 'DOCS_DUPLICATE_MARKSHEET_PRINT_LABEL';
  static DOCS_TRANSCRIPT = 'DOCS_TRANSCRIPT_DEGREE';
  static DOCS_TRANSCRIPT_VERIFY_DOC = 'DOCS_TRANSCRIPT_VERIFY_DOC';
  static DOCS_MARKSHEET_VIEW_AMOUNT = 'DOCS_MARKSHEET_VIEW_AMOUNT';
  static DOCS_TRANSCRIPT_VERIFY_AMOUNT = 'DOCS_TRANSCRIPT_VERIFY_AMOUNT';
  static DOCS_TRANSCRIPT_UPDATE_PRINT_STATUS = 'DOCS_TRANSCRIPT_UPDATE_PRINT_STATUS';
  static DOCS_TRANSCRIPT_UPDATE_CONSIGNMENT_NO = 'DOCS_TRANSCRIPT_UPDATE_CONSIGNMENT_NO';
  static DOCS_TRANSCRIPT_UPDATE_DELIVERY_DATE = 'DOCS_TRANSCRIPT_UPDATE_DELIVERY_DATE';
  static DOCS_TRANSCRIPT_VIEW_AMOUNT = 'DOCS_TRANSCRIPT_VIEW_AMOUNT';
  static DOCS_TRANSCRIPT_PRINT_LABEL = 'DOCS_TRANSCRIPT_PRINT_LABEL';
  static DOCS_CMM_VIEW_AMOUNT = 'DOCS_CMM_VIEW_AMOUNT';
  static DOCS_ORIGINAL_DEGREE_VIEW_AMOUNT = 'DOCS_ORIGINAL_DEGREE_VIEW_AMOUNT';
  static DOCS_PROVISIONAL_VIEW_AMOUNT = 'DOCS_PROVISIONAL_VIEW_AMOUNT';
  static DOCS_MARKSHEET_PRINT_LABEL = 'DOCS_MARKSHEET_PRINT_LABEL';
  static DOCS_CMM_PRINT_LABEL = 'DOCS_CMM_PRINT_LABEL';
  static DOCS_ORIGINAL_DEGREE_PRINT_LABEL = 'DOCS_ORIGINAL_DEGREE_PRINT_LABEL';
  static DOCS_PROVISIONAL_PRINT_LABEL = 'DOCS_PROVISIONAL_PRINT_LABEL';
  static PROFILE: 'PROFILE_ALL_PROFILE';

  static LOG_ACTIVITY_LOG: 'LOG_ACTIVITY_LOG';
}
